<template>
  <div>
    <v-container grid-list-xl fluid>
      <header-title title="Reporte de ESFM/UA"></header-title>
      <p class="grey--text text--darken-1">
        El registro que obtiene es del nuevo sistema, mismo que debe estar
        activo.
      </p>
      <v-row>
        <v-col cols="12" sm="4">
          <v-select
            label="Departamentos"
            :items="departments"
            v-model="options.departamento_id"
            item-text="descripcion"
            item-value="id"
            @change="getInstitutions"
            clearable
          ></v-select>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            class="mr-1"
            label="Buscar"
            v-model="options.search"
            append-icon="mdi-magnify"
            hide-details
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                small
                color="error"
                class="mr-1"
                v-bind="attrs"
                v-on="on"
                @click="reportListInstitution"
                :disabled="institutions.length == 0"
                :loading="pdf_loading"
              >
                PDF
              </v-btn>
            </template>
            <span>Instituciones</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                small
                color="info"
                class="mr-1"
                v-bind="attrs"
                v-on="on"
                @click="reportQuantityInstitution"
                :loading="pdf_loading"
              >
                PDF
              </v-btn>
            </template>
            <span>Cantidad institución</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="institutions"
        :search="options.search"
        :items-per-page="50"
        :footer-props="{
          'items-per-page-options': [25, 50, 100, 150, -1],
        }"
        calculate-widths
        item-key="name"
        class="elevation-2"
        v-cloak
      >
        <template v-slot:[`item.numeration`]="{ item }">
          <td>
            {{ institutions.indexOf(item) + 1 }}
          </td>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <td>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  class="mr-1"
                  type="button"
                  color="info"
                  :loading="btn_detail"
                  @click="showDetail(item.id)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-view-list-outline</v-icon>
                </v-btn>
              </template>
              <span>Ver detalle</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  type="button"
                  color="success"
                  :loading="btn_speciality"
                  @click="getSpecialities(item.id)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-format-list-checkbox</v-icon>
                </v-btn>
              </template>
              <span>Especialidades</span>
            </v-tooltip>
          </td>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog v-model="detailDialog" scrollable max-width="500px">
      <v-form ref="form">
        <v-card>
          <v-card-title class="headline grey lighten-3" primary-title>
            <span class="headline">Detalle</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <h4 class="teal--text">Datos institucionales</h4>
            <v-container grid-list-md>
              <v-row dense>
                <v-col cols="12">
                  <b>Tipo institución: </b>{{ institution.tipo_institucion }}
                </v-col>
                <v-col cols="12" md="6">
                  <b>Código: </b>{{ institution.codigo }}
                </v-col>
                <v-col cols="12" md="6">
                  <b>Fecha creación: </b>{{ institution.fecha_creacion }}
                </v-col>
                <v-col cols="12">
                  <b>Nombre: </b>{{ institution.nombre }}
                </v-col>
                <v-col cols="12">
                  <b>Teléfono/celular: </b>
                  {{ institution.telefono ? institution.telefono : "sin dato" }}
                </v-col>
                <v-col cols="12">
                  <b>Correo electrónico: </b>
                  {{ institution.correo ? institution.correo : "sin dato" }}
                </v-col>
              </v-row>
            </v-container>
            <h4 class="teal--text">Ubicación</h4>
            <v-container grid-list-md>
              <v-row dense>
                <v-col cols="12">
                  <b>Dirección: </b>{{ institution.direccion }}
                </v-col>
                <v-col cols="12">
                  <b>Departamento: </b>
                  {{
                    institution.departamento
                      ? institution.departamento.descripcion
                      : "sin dato"
                  }}
                </v-col>
                <v-col cols="12">
                  <b>Provincia: </b>
                  {{
                    institution.provincia
                      ? institution.provincia.descripcion
                      : "sin dato"
                  }}
                </v-col>
                <v-col cols="12">
                  <b>Municipio: </b>
                  {{
                    institution.municipio
                      ? institution.municipio.descripcion
                      : "sin dato"
                  }}
                </v-col>
                <v-col cols="12">
                  <b>Cantón: </b>
                  {{
                    institution.canton
                      ? institution.canton.descripcion
                      : "sin dato"
                  }}
                </v-col>
                <v-col cols="12">
                  <b>localidad: </b>
                  {{
                    institution.localidad
                      ? institution.localidad.descripcion
                      : "sin dato"
                  }}
                </v-col>
                <v-col cols="12">
                  <b>Coordenada: </b>{{ institution.coordenada }}
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click.native="detailDialog = false"
              >Cerrar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="specialityDialog" scrollable max-width="500px">
      <v-form ref="form">
        <v-card>
          <v-card-title class="headline grey lighten-3" primary-title>
            <span class="headline">Especialidades</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row dense>
              <v-col cols="12">
                <div class="v-data-table theme--light">
                  <div class="v-data-table__wrapper">
                    <table>
                      <thead>
                        <tr>
                          <th class="text-left">#</th>
                          <th class="text-left">Código</th>
                          <th class="text-left">Descripción</th>
                        </tr>
                      </thead>
                      <tbody v-if="specialties.length > 0">
                        <tr v-for="(item, index) in specialties" :key="index">
                          <td>{{ index + 1 }}</td>
                          <td>{{ item.codigo }}</td>
                          <td>{{ item.descripcion }}</td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td class="text-center grey--text" colspan="9">
                            No hay datos disponibles
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click.native="specialityDialog = false"
              >Cerrar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
/* eslint-disable no-console */
import HeaderTitle from "@/components/HeaderTitle";
import Storage from "../../utils/storage";
import Endpoint from "../../utils/environment";
import PDFService from "../../utils/pdf";
import Service from "../../services/request";
import { mapActions } from "vuex";

export default {
  name: "institution",
  components: {
    HeaderTitle,
  },
  data() {
    return {
      loading: false,
      btn_detail: false,
      btn_speciality: false,
      pdf_loading: false,
      headers: [
        { text: "#", value: "numeration", align: "left", sortable: false },
        { text: "Tipo Institución ", value: "tipo_institucion" },
        { text: "Nombre ", value: "nombre" },
        { text: "Departamento ", value: "departamento.descripcion" },
        { text: "Dirección ", value: "direccion", sortable: false },
        { text: "Acciones ", value: "actions", sortable: false },
      ],
      institutions: [],
      specialties: [],
      institution: {
        codigo: "",
        tipo_institucion: "",
        nombre: "",
        direccion: "",
        correo: "",
        telefono: "",
        coordenada: "",
        fecha_creacion: "",
        departamento: {},
        provincia: {},
        cantom: {},
        municipio: {},
        localidad: {},
      },
      departments: [],
      options: {
        search: "",
        departamento_id: "",
      },
      detailDialog: false,
      specialityDialog: false,
      user: null,
      serve: "",
    };
  },
  created() {
    this.serve = Endpoint.getServe();
    this.getDepartmentParameter();
  },
  mounted() {
    const roles = [1, 8];
    this.user = Storage.getUser();
    if (this.user && roles.includes(this.user.rid)) {
      this.getInstitutions();
    } else {
      this.$router.replace({ name: "dashboard" });
    }
  },
  methods: {
    ...mapActions(["toast"]),
    getDepartmentParameter() {
      Service.svcDepartmentParameter("get")
        .then((response) => {
          this.departments = response.data;
        })
        .catch((error) => {
          console.error("No cargo los registros", error);
        });
    },
    getInstitutions() {
      this.loading = true;
      Service.svcInstitution("post", this.options)
        .then((response) => {
          this.loading = false;
          this.institutions = response.data;
        })
        .catch((error) => {
          this.loading = false;
          console.error("Error al cargar registros", error);
        });
    },
    showDetail(institution_id) {
      this.btn_detail = true;
      Service.svcInstitutionById("get", { institution_id: institution_id })
        .then((response) => {
          this.btn_detail = false;
          this.institution = response.data;
          this.detailDialog = true;
        })
        .catch((error) => {
          this.btn_detail = false;
          console.error("No cargo el registro", error);
        });
    },
    getSpecialities(institution_id) {
      this.btn_speciality = true;
      this.specialties = [];
      Service.svcSpecialitiesByInstitution("post", {
        institution_id: institution_id,
      })
        .then((response) => {
          this.btn_speciality = false;
          this.specialties = response.data;
          this.specialityDialog = true;
        })
        .catch((error) => {
          this.btn_speciality = false;
          console.error("No cargo el registro", error);
        });
    },
    clearFilter() {
      this.options.departamento_id = "";
      this.institutions = [];
    },
    reportListInstitution() {
      this.pdf_loading = true;
      /* const institution = this.departments.find(
        (item) => item.codigo === this.options.departamento_id
      ); */
      PDFService.pdfInstitution(this.institutions);
      this.pdf_loading = false;
    },
    reportQuantityInstitution() {
      this.pdf_loading = false;
      Service.svcQuantityTeacher("get")
        .then((response) => {
          PDFService.quantityTeachers(response.data);
          this.pdf_loading = false;
        })
        .catch(() => {
          this.pdf_loading = false;
        });
    },
  },
};
</script>
